import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import './Main.scss';

import Icon from '../UI/Icon/Icon';
import Home from '../../containers/Home/Home';
import Location from '../../containers/Location/Location';
import Standard from '../../containers/Standard/Standard';
import Apartments from '../../containers/Apartments/Apartments';
import Sale from '../../containers/Sale/Sale';
import About from '../../containers/About/About'
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';

const TRANSITION_DELAY = '.5s';

const Main = props => {

    // console.log(props.location.pathname);

    // setTimeout(() => {
    //     console.log(props.navRef);
    // }, 2000);

    const shortMain = [
        '/', 
        '/lokalizacja', 
        '/standard/czesci-wspolne', 
        '/standard/materialy', 
        '/standard/media', 
        '/sprzedaz', 
        '/o-nas/firma', 
        '/o-nas/kontakt', 
        '/o-nas/la-maison', 
        '/o-nas/das-haus', 
        '/o-nas/talo'
    ].indexOf(props.location.pathname) > -1;

    const whiteBackground = [
        '/',
        '/lokalizacja',
        '/o-nas/la-maison', 
        '/o-nas/das-haus', 
        '/o-nas/talo'
    ].indexOf(props.location.pathname) > -1;

    let mainStyle = {};
    let mainClasses = null;
	// if (props.windowWidth >= 767 && shortMain) {
    //     let mainTranslate = null;
    //     let transitionDelay = null;
    //     if (props.showMain) {
    //         mainTranslate = 'translateX(0)';
    //         transitionDelay = TRANSITION_DELAY;
    //     } else {
    //         mainTranslate = `translateX(645px)`;
    //     }

	// 	mainStyle = {
    //         transform: mainTranslate,
    //         transitionDelay
    //     };
        
    //     mainClasses = 'main--narrow';
    // }

    if (props.windowWidth >= 767) {
        let mainTranslate = null;
        let transitionDelay = null;
        if (props.showMain) {
            mainTranslate = 'translateX(0)';
            transitionDelay = TRANSITION_DELAY;
        } else {
            mainTranslate = shortMain ? 'translateX(645px)': 'translateX(100%)';
        }

		mainStyle = {
            transform: mainTranslate,
            transitionDelay
        };
    }
    
    let showMainButtonStyle = null;
    if (!props.showMain) {
        showMainButtonStyle = {
            transform: 'translateX(-120px) translateX(-1rem)',
            transitionDelay: TRANSITION_DELAY
        };
    }

    let button = null;
    if (shortMain) {
        button = (
            <div className="hide-main-button__wrapper">
                <div className="hide-main-button" onClick={() => props.toggleMain(false)}>
                    <span>Schowaj</span>
                    <Icon id="arrow-right" width="16px" height="16px" />
                </div>
            </div>
        );
    }

    if (whiteBackground) {
        mainStyle.backgroundColor = 'rgba(255, 255, 255, 0.8)';
        mainStyle.borderLeftColor = 'white';
    }

	return (
        <>
        <div className={`show-main-button`}
            style={showMainButtonStyle}
             onClick={() => props.toggleMain(true)}>
            <Icon id="arrow-left" width="16px" height="16px" />
            <span>Menu</span>
        </div>
        <main 
            className={shortMain ? 'main--narrow' : null}
			style={mainStyle}>
            <nav ref={props.navRef}>
                <NavigationItems selectItem={props.selectItem} />
            </nav>
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/lokalizacja' component={Location} />
				<Route path='/standard' component={Standard} />
				<Route path='/apartamenty' component={Apartments} />
				<Route path='/sprzedaz' component={Sale} />
				<Route path='/o-nas' component={About} />
				<Redirect to='/' />
			</Switch>
            {button}
		</main>
        </>
	);
}

export default withRouter(Main);
