import React from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import './About.scss';

import Company from './Company/Company';
import Realizations from './Realizations/Realizations';
import Contact from './Contact/Contact';

const About = () => {
    return (
        <section className="about">
            <ul className="navigation-items navigation-items--sub-navigation">
                <li>
                    <NavLink to='/o-nas/kontakt' >Kontakt</NavLink>
                </li>
                <li>
                    <NavLink to='/o-nas/firma' >Firma</NavLink>
                </li>
                <li>
                    <NavLink to='/o-nas/la-maison' >La Maison</NavLink>
                </li>
                <li>
                    <NavLink to='/o-nas/das-haus' >Das Haus</NavLink>
                </li>
                <li>
                    <NavLink to='/o-nas/talo' >Talo</NavLink>
                </li>
            </ul>
            <div className="about__wrapper">
            <Switch>
                <Route path='/o-nas/kontakt' component={Contact} />
                <Route path='/o-nas/firma' component={Company} />
                <Route path='/o-nas/la-maison' component={Realizations} />
                <Route path='/o-nas/das-haus' component={Realizations} />
                <Route path='/o-nas/talo' component={Realizations} />
                <Redirect to='/o-nas/kontakt' />
            </Switch>
            </div>
        </section>
    );
}

export default About;
