import React from 'react';
import './Home.scss';

const Home = () => {
    return (
        <section className="home">
            <img src="/doscasitas-logo.svg" alt="Logo" className="home__img" />
            <p>
            Dos Casitas - to dwa dwulokalowe budynki jednorodzinne w zabudowie bliźniaczej 
w Krakowie, dzielnicy Krowodrza / Wola Justowska, przy ul. 28 lipca 1943, na działce 
nr 290/10. Budynki są położone w układzie tarasowym na naturalnym wzniesieniu terenu. 
Każdy budynek ma 3 kondygnacje naziemne. Pod budynkami znajduje się 
8 stanowiskowy, otwarty garaż. W inwestycji Dos Casitas są łącznie na sprzedaż 
4 mieszkania : dwa jednopoziomowe z ogrodami (poziomi 0) oraz dwa dwupoziomowe 
z tarasami (poziom +1 , +2).  Mieszkania mają od 80 do 135 m2 powierzchni użytkowej zamkniętej. Ponadto przynależy do nich powierzchnia użytkowa odkryta czyli balkony 
i tarasy. Do każdego mieszkania przypisane są dwa miejsca postojowe w garażu podziemnym.
            </p>
            <p>
                Mieszkania sprzedawane są w stanie deweloperskim.
            </p>
            <p>
                Planowane rozpoczęcie budowy: 3 kwartał 2019<br />
                Planowane zakończenie budowy: 3 kwartał 2020
            </p>
        </section>
    );
}

export default Home;
