import React, { useState, useEffect } from 'react';
import './Contact.scss';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Contact = () => {
    const [ email, setEmail ] = useState('');
    const [ phoneNumber, setPhoneNumber ] = useState('');
    const [ content, setContent ] = useState('');
    const [ isFormValid, setIsFormValid ] = useState(true);
    const [ isMessageSent, setIsMessageSent ] = useState(false);

    useEffect(() => {
        setIsFormValid(true);
    }, [email, content]);

    const submit = event => {
        event.preventDefault();
        if ( !validateEmail(email) || !content.length ) {
            setIsFormValid(false);
            return
        }
        console.log({ email, content });

        postData({ email, phoneNumber, content })
            .then(response => {
                console.log(response);
                setEmail('');
                setPhoneNumber('');
                setContent('');
                setIsMessageSent(true);
            });
    }

    const validateEmail = email => {
        return re.test(String(email).toLowerCase());
    }

    const postData = async (data = {}) => {
        const response = await fetch('https://contact.doscasitas.pl', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }

    let error = null;
    if (!isFormValid) {
        error = <p className="error-message">Wpisz poprawny adres e-mail oraz treść wiadomości</p>;
    }

    let sentMessage = null;
    if (isMessageSent) {
        sentMessage = <p className="sent-message">Wiadomość została wysłana</p>;
    }

    return (
        <div className="contact">
            <div className="contact__section__row">
                <div>
                    KECOCO sp. z o.o. sp.k.<br />
                    ul. 28 lipca 1943 32A<br />
                    30-233 Kraków<br />
                    KRS: 0000779095<br />
                    NIP: 6772442959<br />
                    REGON: 382941399<br />
                </div>
                <div>
                    + 48 694 545 482<br />
                    <a href="mailto:biuro@kecoco.com">biuro@kecoco.com</a><br />
                    <a href="http://www.kecoco.com" target="_blank">www.kecoco.com</a><br />
                </div>
            </div>
            <div>
                <form onSubmit={submit}>
                    <div className="two-in-line">
                        <div className="field">
                            <label htmlFor="email">E-mail *</label>
                            <input 
                                id="email"
                                type="text" 
                                value={email}
                                onChange={event => setEmail(event.target.value)} 
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="phoneNr">Nr telefonu</label>
                            <input
                                id="phoneNr"
                                type="text" 
                                value={phoneNumber}
                                onChange={event => setPhoneNumber(event.target.value)} 
                            />
                        </div>
                    </div>
                    <label htmlFor="content">Treść *</label>
                    <textarea
                        id="conten"
                        value={content}
                        onChange={event => setContent(event.target.value)} 
                        rows="5"
                    />

                    <button>Wyślij</button>
                    {error}
                    {sentMessage}
                </form>
            </div>
        </div>
        // <div className="contact">
        //     <div className="contact__section">
        //         <div className="map">
        //         <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2254.7342273390163!2d19.87322367364657!3d50.06221157035911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165be5fb0874df%3A0x37702787b97b3f77!2sKeen%20Concept%20Corporation%20Sp.%20z%20o.%20o.!5e1!3m2!1spl!2spl!4v1583436011975!5m2!1spl!2spl" width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
        //         </div>
        //     </div>
        //     <div className="contact__section">
        //         <div className="contact__section__row">
        //             <div>
        //                 KECOCO sp. z o.o. sp.k.<br />
        //                 ul. 28 lipca 1943 32A<br />
        //                 30-233 Kraków<br />
        //                 KRS: 0000779095<br />
        //                 NIP: 6772442959<br />
        //                 REGON: 382941399<br />
        //             </div>
        //             <div>
        //                 + 48 694 545 482<br />
        //                 <a href="mailto:biuro@kecoco.com">biuro@kecoco.com</a><br />
        //                 <a href="http://www.kecoco.com" target="_blank">www.kecoco.com</a><br />
        //             </div>
        //         </div>
        //         <div>
        //             <form onSubmit={submit}>
        //                 <div className="two-in-line">
        //                     <div className="field">
        //                         <label htmlFor="email">E-mail *</label>
        //                         <input 
        //                             id="email"
        //                             type="text" 
        //                             value={email}
        //                             onChange={event => setEmail(event.target.value)} 
        //                         />
        //                     </div>
        //                     <div className="field">
        //                         <label htmlFor="phoneNr">Nr telefonu</label>
        //                         <input
        //                             id="phoneNr"
        //                             type="text" 
        //                             value={phoneNumber}
        //                             onChange={event => setPhoneNumber(event.target.value)} 
        //                         />
        //                     </div>
        //                 </div>
        //                 <label htmlFor="content">Treść *</label>
        //                 <textarea
        //                     id="conten"
        //                     value={content}
        //                     onChange={event => setContent(event.target.value)} 
        //                     rows="4"
        //                 />

        //                 <button>Wyślij</button>
        //                 {error}
        //                 {sentMessage}
        //             </form>
        //         </div>
        //     </div>
        // </div>
    );
}

export default Contact;

