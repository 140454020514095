import React from 'react';
import './Location.scss';

const Location = () => {
    return (
        <section className="location">
            <p>
            Wola Justowska jest znanym,  prestiżowym i ekskluzywnym miejscem w Krakowie. Otoczenie zieleni - prywatnych ogrodów i terenów leśnych Wzgórza Św. Bronisławy, duży kompleks usług, bliskość do centrum,  lotniska, dobra komunikacja – sprawiają, że miejsce to jest komfortowe i przyjemne do życia. Dos Casitas sąsiaduje z dwoma budynkami o podobnej typologii oraz z ogrodami. W bezpośrednim sąsiedztwie Dos Casitas znajduje się Park Decjusza z renesansowym Pałacem.
            </p>
            <br />
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2254.7342273390163!2d19.87322367364657!3d50.06221157035911!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165be5fb0874df%3A0x37702787b97b3f77!2sKeen%20Concept%20Corporation%20Sp.%20z%20o.%20o.!5e1!3m2!1spl!2spl!4v1583436011975!5m2!1spl!2spl&maptype=roadmap" width="600" height="450" frameBorder="0" allowFullScreen=""></iframe> */}
            <iframe width="100%" height="600" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=ul.%2028%20lipca%201943%2032A+(Dos%20Casitas)&amp;ie=UTF8&amp;t=&amp;z=18&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </section>
    );
}

export default Location;
