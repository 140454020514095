import React from 'react';

import icons from './icons.svg';

const Icon = props => {
    return (
        <svg style={{ width: props.width || '16px', height: props.height || '16px' }} className={props.className}>
            <use href={icons + '#' + props.id}></use>
        </svg>
    );
}

export default Icon;