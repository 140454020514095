import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.scss';

import NavigationItems from '../NavigationItems/NavigationItems';
import Icon from '../../UI/Icon/Icon';

const Header = props => {
    return (
        <header>
            <NavLink to='/' exact>
                <img src="/doscasitas-header.svg" />
            </NavLink>
            {/* <nav ref={props.navRef}>
                <NavigationItems selectItem={props.selectItem} />
            </nav> */}
            <div className="hamburger" onClick={props.openSideMenu}>
                <Icon id="menu" width="22px" height="22px" />
            </div>
        </header>
    );
}

export default Header;
