import React from 'react';
import './Sale.scss';

const Sale = () => {
    return (
        <section className="sale">
            {/* <p>Oferujemy w sprzedaży 4 lokale:</p>
            <ul>
                <li>30DA/1 o powierzchni w cenie:</li>
                <li>30DA/2 o powierzchni w cenie:</li>
                <li>30DB/1 o powierzchni w cenie:</li>
                <li>30DB/1 o powierzchni w cenie:</li>
            </ul>
            <p>
                Aby poznać szczegóły sprzedaży zapraszamy do kontaktu:<br />
                +48 694 54 54 82
            </p>
            <p>Bądź na bieżąco - śledź postępy budywy na <a href="https://www.facebook.com/pages/category/Real-Estate/Kecoco-sp%C3%B3%C5%82ka-z-ograniczon%C4%85-odpowiedzialno%C5%9Bci%C4%85-sp%C3%B3%C5%82ka-komandytowa-112553936973440/" target="_blank" style={{ color: 'inherit', textDecoration: 'underline' }}> facebooku</a><br /></p> */}
            <p>
                Zapraszamy do biura sprzedaży:<br />
                Kraków , ul. 28 lipca 1943  nr  30E/2<br />
                Poniedziałek-piątek godz. : 9-17 <br />
                <br />
                Zapraszamy do kontaktu: <br />
                Tel/sms: (+48) 694 54 54 82 <br />
                e-mail: butelska@kecoco.com <br />
                Cały tydzień  godz.: 9- 21<br />
                <br />
                Z przyjemnością odpowiemy na każde Państwa pytanie.
                <br />
                Aktualny stan budowy - zapraszamy na <a href="https://www.facebook.com/pages/category/Real-Estate/Kecoco-sp%C3%B3%C5%82ka-z-ograniczon%C4%85-odpowiedzialno%C5%9Bci%C4%85-sp%C3%B3%C5%82ka-komandytowa-112553936973440/" target="_blank" style={{ color: 'inherit', textDecoration: 'underline' }}> facebook</a>
            </p>
            <br />
            <p>
                <a href="/cennik.pdf" download style={{ color: 'inherit', textDecoration: 'underline' }}>
                    Pobierz cennik i aktualny stan sprzedaży
                </a>
            </p>
        </section>
    );
}

export default Sale;
