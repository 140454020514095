import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import './Video.scss';

import Icon from '../../components/UI/Icon/Icon';

const Video = () => {
    const [ redirect, setRedirect ] = useState(false);

    const handleRedirect = () => {
        setRedirect(true);
    };

    return (
        <div className="video-wrapper">
            {redirect ? <Redirect to='/' /> : null}

            <div className="skip-button"
                onClick={handleRedirect}>
                <span>Pomiń</span>
                <Icon id="arrow-right" width="16px" height="16px" />
            </div>

            <video src="/SS0_2.mp4" autoPlay muted onEnded={handleRedirect} ></video>
        </div>
    );
}

export default Video;
