import React from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import './Apartments.scss';

const Image0 = () => (
    <img src="/zagospodarowanie.jpg" />
);

const Image1 = () => (
    <img src="/30D1 POZIOM -1.jpg" />
);

const Image2 = () => (
    <img src="/30D1 POZIOM 0.jpg" />
);

const Image3 = () => (
    <img src="/30D2 POZIOM -1.jpg" />
);

const Image4 = () => (
    <img src="/30D2 poziom +1.jpg" />
);

const Image5 = () => (
    <img src="/30D2 poziom +2.jpg" />
);

const Image6 = () => (
    <img src="/30DA1 poziom -1.jpg" />
);

const Image7 = () => (
    <img src="/30DA1 poziom 00.jpg" />
);

const Image8 = () => (
    <img src="/30DA2 poziom -1.jpg" />
);

const Image9 = () => (
    <img src="/30DA2 poziom +1.jpg" />
);

const Image10 = () => (
    <img src="/30DA2 poziom +2.jpg" />
);

const Apartments = () => {

    return (
        // <div>
        //     Apartments Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        // </div>
        <section className="apartments">
            <div>
                {/* <img src="/30D2 poziom +1.jpg" /> */}
                <Switch>
                    <Route path='/apartamenty/zagospodarowanie' component={Image0} />
                    <Route path='/apartamenty/30d1-poziom-1' component={Image1} />
                    <Route path='/apartamenty/30d1-poziom0' component={Image2} />
                    <Route path='/apartamenty/30d2-poziom-1' component={Image3} />
                    <Route path='/apartamenty/30d2-poziom+1' component={Image4} />
                    <Route path='/apartamenty/30d2-poziom+2' component={Image5} />
                    <Route path='/apartamenty/30da1-poziom-1' component={Image6} />
                    <Route path='/apartamenty/30da1-poziom0' component={Image7} />
                    <Route path='/apartamenty/30da2-poziom-1' component={Image8} />
                    <Route path='/apartamenty/30da2-poziom+1' component={Image9} />
                    <Route path='/apartamenty/30da2-poziom+2' component={Image10} />
                    <Redirect to='/apartamenty/zagospodarowanie' />
                </Switch>
            </div>
            <ul className="navigation-items">
                <li>
                    <NavLink to='/apartamenty/zagospodarowanie' exact>Plan zagospodarowania</NavLink>
                </li>
                <li>Ap. nr 30D1</li>
                <li>
                    <NavLink to='/apartamenty/30d1-poziom-1' exact>Poziom -1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30d1-poziom0' exact>Poziom 0</NavLink>
                </li>
                <li>Ap. nr 30D2</li>
                <li>
                    <NavLink to='/apartamenty/30d2-poziom-1' exact>Poziom -1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30d2-poziom+1' exact>Poziom +1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30d2-poziom+2' exact>Poziom +2</NavLink>
                </li>
                <li>Ap. nr 30DA1</li>
                <li>
                    <NavLink to='/apartamenty/30da1-poziom-1' exact>Poziom -1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30da1-poziom0' exact>Poziom 0</NavLink>
                </li>
                <li>Ap. nr 30DA2</li>
                <li>
                    <NavLink to='/apartamenty/30da2-poziom-1' exact>Poziom -1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30da2-poziom+1' exact>Poziom +1</NavLink>
                </li>
                <li>
                    <NavLink to='/apartamenty/30da2-poziom+2' exact>Poziom +2</NavLink>
                </li>
            </ul>
        </section>
    );
}

export default Apartments;
