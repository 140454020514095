import React from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import './Standard.scss';

const Common = () => (
    <>
        <h4>Części wspólne <small>/ wewnętrzne i zewnętrzne</small></h4>
        <ul className="circle">
            <li>wykończone wspólne pomieszczenia wewnętrzne budynków w tym: holle, garaż (posadzki, wykończenie ścian, oprawy świetlne, załączniki, itp.)</li>
            <li>wykończone wspólne przestrzenie zewnętrzne, w tym: dojścia dojazdy (płyty betonowe ażurowe, kostka granitowa, oświetlenie, miejsce na śmieci)</li>
            <li>ogrodzenie panelowe</li>
            <li>brama wjazdowa</li>
            <li>osobne rozdzielnice elektryczne na potrzeby administracyjne (moc przyłączeniowa dla potrzeb administracyjnych dla każdego budynku 11 kW)</li>
        </ul>
    </>
);

const Materials = () => (
    <>
        <h4>Budynek <small>/ konstrukcja i materiał</small></h4>
        <ul className="circle">
            <li>monolityczna żelbetowa konstrukcja w układzie słupowo-tarczowym</li>
            <li>ściany nośne zewnętrzne i wewnętrzne - żelbet (20 cm)</li>
            <li>ściany wypełniające zewnętrzne i wewnętrzne - pustaki ceramiczne POROTHERM P+W (18,8 cm)</li>
            <li>ściany wewnętrzne międzylokalowe - pustaki wapienno-piaskowe SILKA E18A (18 cm)</li>
            <li>ściany działowe - pustaków ceramicznych POROTHERM 11.5 DRYFIX (11,5cm), pustaki ceramiczne POROTHERM 8 P+W (8cm)</li>
            <li>stropy żelbetowe ( gr. 16 cm – stropy między kondygnacyjne i gr.20 cm – strop między garażem a parterem)</li>
            <li>konstrukcja dachu drewniana, wykończenie dachu blacha aluminiowa tytanium na rąbek stojący</li>
            <li>ocieplenie zewnętrzne- styropian fasadowy grafitowy lambda 0,33</li>
            <li>stolarka aluminiowa wraz z parapetami zewnętrznymi w kolorze RAL 9006., profile ciepłe</li>
            <li>schody wewnętrzne i zewnętrzne żelbetowe</li>
            <li>balkony konstrukcja żelbetowa z wykorzystaniem systemu nośnych łączników</li>
            <li>termoizolacyjnych Schöck Isokorb</li>
            <li>tarasy membrana z wykończeniem drewno lub płyty</li>
            <li>balustrady zewnętrzne - stal ocynkowana</li>
            <li>ściany zewnętrzne - tynk mineralny typu baranek w kolorze białym</li>
            <li>ściany wewnętrzne - tynk gipsowy, tynk cementowo-wapienny</li>
        </ul>
    </>
);

const Media = () => (
    <>
        <h4>Media</h4>
        <ul className="circle">
            <li>instalacja c.o. (kotły gazowe kondensacyjne z zasobnikiem c.w.u. o pojemności 120 l.)</li>
            <li>ogrzewanie podłogowe</li>
            <li>instalacja gazowa zasilającą kotły i kuchnie gazowe</li>
            <li>instalacja wodno-kanalizacyjna</li>
            <li>instalacja elektryczna (moc przyłączeniowa dla każdego mieszkania 14kW)</li>
            <li>system wentylacji mechanicznej nawiewno-wywiewnej z odzyskiem ciepła</li>
            <li>instalacja odgromowa</li>
            <li>ogrzewanie rur spustowych</li>
            <li>ochrona przepięciowa</li>
            <li>ochrona przeciwporażeniowa</li>
            <li>okablowanie światłowodowe</li>
            <li>system domofonowy</li>
            <li>tv/sat</li>
        </ul>
    </>
);

const Standard = () => {
    return (
        // <div>
        //     Standard Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        // </div>
        <section className="standard">
            <ul className="navigation-items navigation-items--sub-navigation">
                <li>
                    <NavLink to='/standard/czesci-wspolne' exact>Części wspólne</NavLink>
                </li>
                <li>
                    <NavLink to='/standard/materialy' exact>Budynek</NavLink>
                </li>
                <li>
                    <NavLink to='/standard/media' exact>Media</NavLink>
                </li>
            </ul>
            <div className="standard__container">
                <Switch>
                    <Route path='/standard/czesci-wspolne' component={Common} />
                    <Route path='/standard/materialy' component={Materials} />
                    <Route path='/standard/media' component={Media} />
                    <Redirect to='/standard/czesci-wspolne' />
                </Switch>
            </div>
        </section>
    );
}

export default Standard;
