import React from 'react';

import './SideMenu.scss';
import NavigationItems from '../NavigationItems/NavigationItems';

const sideMenu = (props) => (
    <div
        className={`side-menu ${props.open ? 'side-menu--open' : 'side-menu--close'}`}
        onClick={props.onClose} >
        <NavigationItems selectItem={() => {}} />
    </div>
);

export default sideMenu;