import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationItems.scss';

const NavigationItems = props => {
    return (
        <ul className="navigation-items">
            <li>
                <NavLink className="nav-link" to='/' onClick={() => props.selectItem(0)} exact>Dos Casitas</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/lokalizacja' onClick={() => props.selectItem(1)}>Lokalizacja</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/standard' onClick={() => props.selectItem(2)}>Standard</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/apartamenty' onClick={() => props.selectItem(-1)}>Apartamenty</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/sprzedaz' onClick={() => props.selectItem(4)}>Sprzedaż</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to='/o-nas' onClick={() => props.selectItem(5)}>O nas</NavLink>
            </li>
        </ul>
    );
}

export default NavigationItems;
