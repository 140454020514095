import React, { useState, useRef, useEffect, useCallback  } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './App.scss';

import Site from './containers/Site/Site';
import Video from './containers/Video/Video';

const App = () => {

	const img1 = new Image();
	const img2 = new Image();
	const img3 = new Image();
	const img4 = new Image();
	const img5 = new Image();
	const img6 = new Image();
	const img7 = new Image();
	const img8 = new Image();
	const img9 = new Image();
	const img10 = new Image();
	const img11 = new Image();
	const img12 = new Image();
	const img13 = new Image();
	const img14 = new Image();
	const img15 = new Image();
	const img16 = new Image();
	const img17 = new Image();
	const img18 = new Image();
	const img19 = new Image();

	const img20 = new Image();
	const img21 = new Image();
	const img22 = new Image();
	const img23 = new Image();
	const img24 = new Image();
	const img25 = new Image();
	const img26 = new Image();
	const img27 = new Image();
	const img28 = new Image();
	const img29 = new Image();
	const img30 = new Image();

	img10.src = '/SS1-min.jpg';
	img11.src = '/SS2-min.jpg';
	img12.src = '/SS3-min.jpg';
	img13.src = '/SS4-min.jpg';
	img14.src = '/SS5-min.jpg';

	img1.src = '/1-DSC_3310JPG-min.jpg';
	img2.src = '/DSC_7922-min.jpg';
	img3.src = '/DSC_7852JPG-min.jpg';
	img4.src = '/SS5-min.jpg';
	img5.src = '/DSC_7936JPG-min.jpg';
	img6.src = '/DSC_7851JPG-min.jpg';
	img7.src = '/la-maison.jpg';
	img8.src = '/10-min.jpg';
	img9.src = '/talo1.jpg';
	
	img15.src = '/doscasitas-logo.svg';
	img16.src = '/kecoco-logo.svg';
	img17.src = '/lamaison-logo.svg';
	img18.src = '/dashaus-logo.svg';
	img19.src = '/talo-logo.svg';

	img20.src = '/zagospodarowanie.jpg';
	img21.src = '/30D1 POZIOM -1.jpg';
	img22.src = '/30D1 POZIOM 0.jpg';
	img23.src = '/30D2 POZIOM -1.jpg';
	img24.src = '/30D2 poziom +1.jpg';
	img25.src = '/30D2 poziom +2.jpg';
	img26.src = '/30DA1 poziom -1.jpg';
	img27.src = '/30DA1 poziom 00.jpg';
	img28.src = '/30DA2 poziom -1.jpg';
	img29.src = '/30DA2 poziom +1.jpg';
	img30.src = '/30DA2 poziom +2.jpg';

	return (
		<Switch>
			<Route path='/intro' component={Video} />
			<Route path='/' component={Site} />
			<Redirect to='/' />
		</Switch>
	);
}

export default withRouter(App);