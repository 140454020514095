import React from 'react';
import { Route, Switch, Redirect, withRouter, NavLink } from 'react-router-dom';
import './Realizations.scss';

const LaMaison = () => (
    // <section id="realizations-second" className="section--show">
    //     <div className="realizations-top">
    //         <img src="/dashaus-logo.svg" alt="Das Haus" className="img--in-text img--top-and-left" />
    //         <p>Kraków, ul. Powstania Styczniowego 2J, 2K, 2L, 2M</p>
    //         <p>Rok budowy: 2013</p>
    //         <p>Projekt jako jeden z dziesięciu wyłonionych w drodze konkursu SARP na szczeblu krajowym reprezentował Polskę na wystawie LA MAISON V4 Family Houses VI EDYCJA - wystawa najlepszych przykładów architektury jednorodzinnej powstałej w&nbsp;ostatnich latach w krajach Grupy Wyszehradzkiej.</p>
    //         <p>Projekt uzyskał wyróżnienie w konkursie „KREISEL - ELEWACJA 2013” w kategorii budynek do 1000 m2</p>
    //         <p>
    //             <a href="http://www.bryla.pl/bryla/1,85301,17577513,Das_Haus___blizniak_z_Krakowa.html" target="_blank">Artykuł o projekcie na portalu bryla.pl</a>
    //         </p>
    //     </div>
    //     <div className="gallery gallery--das-haus">
    //         <div style={{ backgroundImage: "url('/dashaus1.png')" }}></div>
    //         <div style={{ backgroundImage: "url('/dashaus2.jpeg')" }}></div>
    //         <div style={{ backgroundImage: "url('/dashaus3.jpeg')" }}></div>
    //     </div>
    // </section>
    <div>
        <img src="/lamaison-logo.svg" alt="La Maison" className="realizations__logo"></img>
        <p>Kraków, ul. 28 lipca 1943 30B, 30C</p>
        <p>Rok budowy: 2010</p>
        <p>Projekt jako jeden z dziesięciu wyłonionych w drodze konkursu SARP na szczeblu krajowym reprezentował Polskę na wystawie LA MAISON V4 Family Houses – IV EDYCJA - wystawa najlepszych przykładów architektury jednorodzinnej powstałej w&nbsp;ostatnich latach w krajach Grupy Wyszehradzkiej.</p>  
    </div>
);

const LaMaisonImages = () => (
    <div className="gallery gallery--la-maison">
        <div style={{ backgroundImage: "url('/lamaison1.jpeg'" }}></div>
        <div style={{ backgroundImage: "url('/lamaison2.jpeg'" }}></div>
        <div style={{ backgroundImage: "url('/lamaison3.jpeg'" }}></div>
    </div>
);

const DasHaus = () => (
    <div>
        <img src="/dashaus-logo.svg" alt="Das Haus" className="realizations__logo" />
        <p>Kraków, ul. Powstania Styczniowego 2J, 2K, 2L, 2M</p>
        <p>Rok budowy: 2013</p>
        <p>Projekt jako jeden z dziesięciu wyłonionych w drodze konkursu SARP na szczeblu krajowym reprezentował Polskę na wystawie LA MAISON V4 Family Houses VI EDYCJA - wystawa najlepszych przykładów architektury jednorodzinnej powstałej w&nbsp;ostatnich latach w krajach Grupy Wyszehradzkiej.</p>
        <p>Projekt uzyskał wyróżnienie w konkursie „KREISEL - ELEWACJA 2013” w kategorii budynek do 1000 m2</p>
        <p>
            <a href="http://www.bryla.pl/bryla/1,85301,17577513,Das_Haus___blizniak_z_Krakowa.html" target="_blank">Artykuł o projekcie na portalu bryla.pl</a>
        </p>
    </div>
);

const DasHausImages = () => (
    <div className="gallery gallery--das-haus">
        <div style={{ backgroundImage: "url('/dashaus1.png')" }}></div>
        <div style={{ backgroundImage: "url('/dashaus2.jpeg')" }}></div>
        <div style={{ backgroundImage: "url('/dashaus3.jpeg')" }}></div>
    </div>
);

const Talo = () => (
    <div>
        <img src="/talo-logo.svg" alt="Talo" className="realizations__logo" />
        <p>Kraków, ul. 28 lipca 1943 nr 30E, 30F</p>
        <p>Rok budowy: 2016</p>  
    </div>
);

const TaloImages = () => (
    <div className="gallery gallery--talo">
        <div style={{ backgroundImage: "url('/talo1.jpeg')" }}></div>
        <div style={{ backgroundImage: "url('/talo2.jpeg')" }}></div>
        <div style={{ backgroundImage: "url('/talo3.jpeg')" }}></div>
    </div>
);

const Realizations = () => {
    return (
        <Switch>
            <Route path='/o-nas/la-maison' component={LaMaison} />
            <Route path='/o-nas/das-haus' component={DasHaus} />
            <Route path='/o-nas/talo' component={Talo} />
        </Switch>
        // <div className="realizations">
        //     <div className="realizations__wrapper">
        //         <ul className="navigation-items realizations__navigation">
        //                 <li>
        //                     <NavLink to='/o-nas/realizacje/la-maison' exact>La Maison</NavLink>
        //                 </li>
        //                 <li>
        //                     <NavLink to='/o-nas/realizacje/das-haus' exact>Das Haus</NavLink>
        //                 </li>
        //                 <li>
        //                     <NavLink to='/o-nas/realizacje/talo' exact>Talo</NavLink>
        //                 </li>
        //             </ul>
        //         <div className="realizations__top ">
        //             <Switch>
        //                 <Route path='/o-nas/realizacje/la-maison' component={LaMaison} />
        //                 <Route path='/o-nas/realizacje/das-haus' component={DasHaus} />
        //                 <Route path='/o-nas/realizacje/talo' component={Talo} />
        //                 <Redirect to='/o-nas/realizacje/la-maison' />
        //             </Switch>
        //         </div>
        //     </div>
        //     <Switch>
        //         <Route path='/o-nas/realizacje/la-maison' component={LaMaisonImages} />
        //         <Route path='/o-nas/realizacje/das-haus' component={DasHausImages} />
        //         <Route path='/o-nas/realizacje/talo' component={TaloImages} />
        //     </Switch>
        // </div>
    );
}

export default Realizations;
