import React, { useState, useRef, useEffect, useCallback  } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Site.scss';

import Header from '../../components/Navigation/Header/Header';
import Footer from '../../components/Footer/Footer';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import Main from '../../components/Main/Main';
import useWindowDimensions from '../../hooks/getWindowDimensions';

const Site = props => {
	const [ showSideMenu, setShowSideMenu ] = useState(false);
	const [ navWidth, setNavWidth ] = useState(0);
	const [ showMain, setShowMain ] = useState(false);
	const [ selectedImage, setSelectedImage ] = useState(0);
	const navRef = useRef();
	const { height, width } = useWindowDimensions();
	let timer = null;
	let autoPlayTimer = null;
	const corouselRef = useRef();
	const [ isAutoPlayOn, setIsAutoPlayOn ] = useState(false);

    const toggleSideMenu = () => {
        setShowSideMenu(prevShowSideMenu => !prevShowSideMenu);
	};

    const closeSideMenu = () => {
        setShowSideMenu(false);
	};

	useEffect(() => {
		console.log(corouselRef.current);
		timer = setTimeout(() => {
			// stopAutoPlay();
			setShowMain(true);
			// setSelectedImage(null);
		}, 15000);

		startAutoPlay();
	}, []);

	useEffect(() => {
		setSelectedImage(null);
	}, [props.location.pathname]);

	const toggleMain = state => {
		if (state) {
			console.log('stopAutoPlay');
			if (props.location.pathname !== '/') {
				stopAutoPlay();
				setSelectedImage(null);
			}
				
			
		} else {
			console.log('startAutoPlay');
			startAutoPlay();
		}
		setShowMain(state);
		clearTimeout(timer);
	};

	const startAutoPlay = () => {
		if (!isAutoPlayOn) {
			autoPlay(autoPlayTimer, 3000);
			setIsAutoPlayOn(true);
		}

	};

	const stopAutoPlay = () => {
		// clearTimeout(autoPlayTimer);
			let id = setTimeout(function() {}, 0);
			while (id--) {
				console.log(id);
				clearTimeout(id);
			}
			setIsAutoPlayOn(false);
	};

	const incrementImage = () => {
		setSelectedImage( corouselRef.current ? corouselRef.current.state.selectedItem + 1 : 0 );
	};

	const autoPlay = (timer, interval) => {
		console.log('autoPlay');
		timer = setTimeout(() => {
			incrementImage();
			autoPlay(timer, interval);
		}, interval);
	};

	const selectImage = index => {
		console.log(index);
        setSelectedImage(index);
        setShowMain(true);
	};
	
	const testF = () => {
		console.log('testFd');
		// clearAllTimeouts();
		console.log(corouselRef);
	};

	let carousel = null;
	let backgroundImage = null;
	let background = null;

	if (true) { //(!showMain && props.location.pathname.indexOf('apartamenty') === -1) {
		// let imageIndex = null;
		// switch (props.location.pathname) {
		// 	case '/lokalizacja':
		// 		imageIndex = 1;
		// 		break;
		// 	case '/standard/czesci-wspolne':
		// 		imageIndex = 2;
		// 		break;
		// 	case '/standard/materialy':
		// 		imageIndex = 3;
		// 		break;
		// 	case '/standard/media':
		// 		imageIndex = 4;
		// 		break;
		// 	case '/sprzedaz':
		// 		imageIndex = 5;
		// 		break;
		// 	case '/o-nas/kontakt':
		// 		imageIndex = 6;
		// 		break;
		// 	case '/o-nas/firma':
		// 		imageIndex = 6;
		// 		break;
		// 	case '/o-nas/la-maison':
		// 		imageIndex = 8;
		// 		break;
		// 	case '/o-nas/das-haus':
		// 		imageIndex = 9;
		// 		break;
		// 	case '/o-nas/talo':
		// 		imageIndex = 10;
		// 		break;
		// 	// case '/':
		// 	// 	imageIndex = 0;
		// 	// 	break;
		// 	default:
		// 		break;
		// }

		// console.log(selectedImage, imageIndex);

		

		if (props.location.pathname === '/') {
			carousel = (
				<Carousel 
                showThumbs={false} 
                showStatus={false} 
                selectedItem={selectedImage} 
                showArrows={false}
				showIndicators={!showMain}
				infiniteLoop={true}
				// transitionTime={0}
				// autoPlay={false}
				// onChange={resetAutoPlay}
				// onClickThumb={testF}
				// interval={5000}
				ref={corouselRef}
				className="dos-casitas__carousel"
				 >
					<div>
						<img src="" style={{ backgroundImage: `url(${'/SS1-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
						<p className="legend" style={{ mixBlendMode: 'unset' }}>Elewacja wschodnia</p>
					</div>
					<div>
						<img src="" style={{ backgroundImage: `url(${'/SS2-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
						<p className="legend">Elewacja wschodnia</p>
					</div>
					<div>
						<img src="" style={{ backgroundImage: `url(${'/SS3-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
						<p className="legend">Widok od południa</p>
					</div>
					<div>
						<img src="" style={{ backgroundImage: `url(${'/SS4-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
						<p className="legend">Widok północy</p>
					</div>
					<div>
						<img src="" style={{ backgroundImage: `url(${'/SS5-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
						<p className="legend">Maszrabija elewacji zachodniej</p>
					</div>
				</Carousel>
			);

			if (!isAutoPlayOn) {
				startAutoPlay();
			}
		} else if (props.location.pathname.indexOf('apartamenty') === -1) {
			// console.log(imageIndex);
			// console.log(corouselRef.current?.itemsRef.length);
			// console.log(corouselRef.current);
			// carousel = (
			// 	<Carousel 
            //     showThumbs={false} 
            //     showStatus={false} 
            //     selectedItem={imageIndex} 
            //     showArrows={false}
			// 	showIndicators={!showMain}
			// 	infiniteLoop={true}
			// 	// transitionTime={0}
			// 	// autoPlay={!showMain}
			// 	// onChange={resetAutoPlay}
			// 	// onClickThumb={testF}
			// 	// interval={5000}
			// 	ref={corouselRef}
			// 	 >
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/SS1-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/1-DSC_3310JPG-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/DSC_7922-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 			<p className="legend">Dos Casitas Elewacja wschodnia</p>
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/DSC_7852JPG-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 			<p className="legend">Talo Elewacja</p>
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/SS5-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 			<p className="legend">Dos Casitas Detal maszrabiji elewacja zachodnia</p>
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/DSC_7936JPG-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/DSC_7851JPG-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/1-hall30f-001JPG-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/lamaison1.jpeg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/10-min.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 		<div>
			// 			<img src="" style={{ backgroundImage: `url(${'/talo.jpg'})`, backgroundSize: 'cover', height: '100vh' }} />
			// 		</div>
			// 	</Carousel>
			// );

			switch (props.location.pathname) {
				case '/lokalizacja':
					backgroundImage = '/1-DSC_3310JPG-min.jpg';
					background = <div key={0} className="dos-casitas__background" style={{ backgroundImage: `url('/1-DSC_3310JPG-min.jpg')` }}>
							<div>Taras w Talo - widok na sikornik</div>
						</div>
					break;
				case '/standard/czesci-wspolne':
					backgroundImage = '/DSC_7922-min.jpg';
					background = <div key={1} className="dos-casitas__background" style={{ backgroundImage: `url('/DSC_7922-min.jpg')` }}>
						<div>Dos Casitas Elewacja wschodnia</div>
					</div>
					break;
				case '/standard/materialy':
					backgroundImage = '/DSC_7852JPG-min.jpg';
					background = <div key={2} className="dos-casitas__background" style={{ backgroundImage: `url('/DSC_7852JPG-min.jpg')` }}>
						<div>Talo Elewacja</div>
					</div>
					break;
				case '/standard/media':
					backgroundImage = '/SS5-min.jpg';
					background = <div key={3} className="dos-casitas__background" style={{ backgroundImage: `url('/SS5-min.jpg')` }}>
						<div>Maszrabija elewacji zachodniej</div>
					</div>
					break;
				case '/sprzedaz':
					backgroundImage = '/DSC_7936JPG-min.jpg';
					background = <div key={4} className="dos-casitas__background" style={{ backgroundImage: `url('/DSC_7936JPG-min.jpg')` }}>
						<div style={{ mixBlendMode: 'unset'}}>Wschód słońca nad Krakowem z Talo</div>
					</div>
					break;
				case '/o-nas/kontakt':
					backgroundImage = '/DSC_7851JPG-min.jpg';
					background = <div key={5} className="dos-casitas__background" style={{ backgroundImage: `url('/DSC_7851JPG-min.jpg')` }}>
						<div>Talo detal elewacji</div>
					</div>
					break;
				case '/o-nas/firma':
					backgroundImage = '/DSC_7851JPG-min.jpg';
					background = <div key={5} className="dos-casitas__background" style={{ backgroundImage: `url('/DSC_7851JPG-min.jpg')` }}>
						<div>Talo detal elewacji</div>
					</div>
					break;
				case '/o-nas/la-maison':
					backgroundImage = '/lamaison1.jpeg';
					background = <div key={7} className="dos-casitas__background" style={{ backgroundImage: `url('/la-maison.jpg')` }}>
						<div></div>
					</div>
					break;
				case '/o-nas/das-haus':
					backgroundImage = '/10-min.jpg';
					background = <div key={8} className="dos-casitas__background" style={{ backgroundImage: `url('/10-min.jpg')` }}>
						<div style={{ mixBlendMode: 'unset'}}>Das Haus elewacja</div>
					</div>
					break;
				case '/o-nas/talo':
					backgroundImage = '/talo.jpg';
					background = <div key={9} className="dos-casitas__background" style={{ backgroundImage: `url('/talo1.jpg')` }}>
						<div>Talo elewacja południowa</div>
					</div>
					break;
				default:
					break;
			}
		}
	}

	console.log('showMain: ' + showMain);
	
	return (
		<>
            {carousel}
			{background}
			{/* <div style={backgroundImage ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', height: '100vh' } : null}></div> */}
			<section className="dos-casitas"
				// style={{ backgroundImage: `url(${'./DSC_7851.JPG'})` }}
				>
				<Header
					// navRef={navRef}
					openSideMenu={toggleSideMenu}
					// selectItem={selectImage}
				/>
				<SideMenu
					open={showSideMenu}
					onClose={closeSideMenu} />
				<Main
					// navWidth={navWidth}
					showMain={showMain}
					windowWidth={width}
					toggleMain={toggleMain}
					// navRef={navRef}
					selectItem={selectImage}
				/>
					
				<Footer />
			</section>
		</>
	);
}

export default withRouter(Site);