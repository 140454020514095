import React from 'react';
import './Footer.scss';

import Icon from '../UI/Icon/Icon';

const Footer = () => {
    return (
        <footer>
            <div className="footer__aside">KECOCO 2020</div>
            <div className="footer__center">
                Przedsprzedaż DO KOŃCA KWIETNIA 2020!
            </div>
            <div className="footer__aside footer__aside--right">
                <span>694 545 482 </span>
                <Icon id="phone" width="22px" height="22px" />
                <a href="https://www.facebook.com/pages/category/Real-Estate/Kecoco-sp%C3%B3%C5%82ka-z-ograniczon%C4%85-odpowiedzialno%C5%9Bci%C4%85-sp%C3%B3%C5%82ka-komandytowa-112553936973440/" target="_blank" >
                 <Icon id="facebook" width="22px" height="22px" />
                </a>
                <a href="mailto:biuro@kecoco.com"><Icon id="mail" width="22px" height="22px" /></a>
            </div>
        </footer>
    );
}

export default Footer;
