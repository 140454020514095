import React from 'react';
import './Company.scss';

const Company = () => {
    return (
        <div className="company">
            <img src="/kecoco-logo.svg" alt="kecoco" />
            <p>
                Zajmujemy się projektowaniem architektonicznym i działalnością na rynku nieruchomości. Każdy dom przeznaczony na sprzedaż projektujemy indywidualnie w zależności od miejsca, jego położenia i warunków lokalnych. Przy realizacji budynków wykorzystujemy dobre i naturalne materiały. Stosujemy bardzo duże przeszklenia aby światło było dominującym elementem w mieszkaniach i dawało energię do życia. Stosujemy nowe rozwiązania zgodnie z postępem technologicznym i wychodzimy naprzeciw architekturze pozbawionej sztuczności. 
            </p>
        </div>
    );
}

export default Company;
